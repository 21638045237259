.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backAppBar {
  background-color: var(--blue-0);
  width: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
  /* 양옆 여백 */
  padding: 3.438rem 0.6875rem 0.6875rem 1.3125rem;
  border-radius: 0 0 0.9375rem 0.9375rem;
  background-color: var(--blue-0);
}

.contentTop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-3-1);
  font-size: 0.75rem;
}

.contentTopLeft {
  display: flex;
  align-items: center;
  gap: 0.4375rem;
}

.dot {
  font-weight: 800;
}

.dot3 {
  padding: 0.625rem;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: end;
  padding: 0.5rem 0.625rem 1.625rem 0;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--gray-4);
}

.views {
  white-space: nowrap;
  color: var(--blue-3);
  font-size: 0.6875rem;
  padding-left: 0.125rem;
  font-weight: 400;
}

.post_bottom {
  display: flex;
  justify-content: end;
  padding: 0 0.625rem 0 0;

  div:nth-of-type(2):hover,
  div:nth-of-type(3):hover {
    background-color: rgba(var(--white-rgb), 0.8);
    cursor: pointer;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.3125rem;
  gap: 0.375rem;
  color: var(--gray-3-1);
  font-size: 0.6875rem;
}

.whiteBox {
  background-color: #fff;
  width: 100%;
  height: 50px;
}

/* post content */

.contentText {
  font-size: 0.75rem;
  color: var(--gray-4);
  font-size: 0.875rem;
  padding: 0 0.8125rem 0.9375rem 0;
  white-space: pre-wrap;
  word-break: keep-all;

  a {
    color: var(--blue-3);
  }

  a:hover {
    text-decoration: underline;
  }
}
